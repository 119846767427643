.services{
    display: flex;
    padding: 0 3rem 0 3rem;
    height: 90vh;
    margin-top: 9rem;
    margin-bottom: 13rem;
}
.awesome{
    display: flex;
    flex-direction: column;
}
.awesome>:nth-child(1){
    color: var(--black);
    font-size: 2.5rem;
    font-weight: bold;
}
.awesome>:nth-child(2){
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;
}
.awesome>:nth-child(3){
    color: var(--gray);
    font-size: 14px;
    margin-top: 1rem;
}
.btn-a{
    margin-top: 1rem;
    width: 8rem;
    height: 2rem;
}
.s-blur1{
    top: -2rem;
    left: -20rem;
    background: rgb(193, 245, 255);
    position: relative;
}
.s-blur2{
    top: 8rem;
    left: 14rem;
}
.s-card{
    position: relative;
}
.s-card>*{
    position: absolute;
}


@media screen and (max-width:480px) {
    .services{
        margin-top: 2rem;
        flex-direction: column;
        gap: 5rem;
        height: 66rem;
        padding: 0;
    }
    .s-card{
        display: flex;
        flex-direction: column;
        gap: 17rem;
        margin-top: -12rem;
    }
    .s-card>*{
        position: static;
    }
    
}