.n-wapper {
    display: flex;
    justify-content: space-between;
    background:linear-gradient(rgba(255, 255, 255, 0.7),rgba(255, 255, 255, 0.7));
    position: fixed;
    z-index: 100;
    width: 100%;
    margin-left: -3.5rem;
    margin-top: -0.5rem;
    height: 12vh;
}

.n-left {
    display: flex;
    margin-left: 4rem;
    gap: 2rem;
    align-items: center;
    flex: 1;
}
.n-name{
    font-weight: bold;
    font-size: 1.5rem;
}
.n-right{
    flex: 1;
    display: flex;
    align-items: center;
    font-weight: 400;
    margin-right: 4rem;
}
.n-list{
    flex: 10;
}
.n-list>ul{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-right: 4rem;
    list-style-type: none;
    cursor: pointer;
}
.n-list ul li:hover{
    cursor: pointer;
    color: var(--orange);
}
.n-button{
    flex: 2;
    width: 6rem;
}
@media screen and (max-width :480px ) {
    .n-list{
        display: none;
    }
    .n-button{
        display: none;
    }
}