 .intro{
    display: flex;
    height: 77vh;
    margin-top: 8rem;
    
 }
 .i-left{
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 2rem;
}
.i-name{
    display: flex;
    flex-direction: column;
}
.i-name>:nth-child(1){
    color: var(--black);
    font-size: 4rem;
    font-weight: bold;
}
.i-name>:nth-child(2){
    color: var(--orange);
    font-size: 4rem;
    font-weight: bold;
}
.i-name>:nth-child(3){
    color: var(--gray);
    font-size: 1rem;
    margin-top: 10px;
    font-weight: 100;
}
.i-button{
    width: 6rem;
    height: 2rem;
}
.i-icons{
    display: flex;
    gap: 0rem;
    margin-top: 3rem;
}
.i-icons>a>img{
    transform: scale(0.5);
}
.i-icons:hover{
    cursor: pointer;
}
 .i-right{
    flex: 1;
    position: relative;
 }
 .i-right>*{
    position: absolute;
    z-index: 1;
 }
 .i-right>:nth-child(1){
    transform: scale(0.69);
    left: -15%;
    top: -9%;
 }
 .i-right>:nth-child(2){
    transform: scale(0.67);
    left: -3rem;
    top: -4.6rem;
 }
 .i-right>:nth-child(3){
    transform: scale(1.4);
    left: 28%;
 }
 /* .i-right>:nth-child(3){
    transform: scale(1.2);
    top: -19%;
    left: 7%;
 } */
 .i-right>:nth-child(4){
    transform: scale(0.6);
   top: -19%;
   left: -24%;
   border-radius: 50%;
   padding: 0;
 }
.blur{
    background: rgb(238, 210, 255);
    width: 22rem;
    height: 14rem;
    background: #edd0ff;
    filter: blur(72px);
    border-radius: 50%;
    top: -18%;
    left: 56%;
    z-index: -9;
}
@media screen and (max-width :480px ) {
    .intro{
        flex-direction: column;
        height: 64rem;
        gap: 7rem;
    }
    .i-right{
        transform: scale(0.8);
        left: -3rem;
    }
    .i-right .blur{
        display: none;
    }
    .floating-div{
        top: -7.5rem !important;
    }
  
    .i-right>:nth-child(1){
        transform: scale(0.69);
        left: -27%;
        top: -9%;
     }
     .i-right>:nth-child(2){
        transform: scale(0.67);
        left: -4rem;
        top: -4.6rem;
     }
     .i-right>:nth-child(3){
        transform: scale(1.4);
        left: 40%;
        top: 0.2rem;
     }
}