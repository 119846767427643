.toogle{
    display: flex;
    justify-content: space-between;
    border: 3px solid var(--orange);
    border-radius: 1rem;
    padding: 2px;
    cursor: pointer;
    position: relative;
}
.toogle>*{
    width: 1rem;
    height: 1rem;
    color: var(--orange);
}
.t-button{
    background: var(--orange);
    border-radius: 100%;
    position: absolute;
}
@media screen and (max-width :480px ) {
    .toogle>*{
        width: 1.2rem;
        height: 1.2rem;
    }
}