.card{
    display: flex;
    flex-direction: column;
    width: 10rem;
    height: 13rem;
    position: absolute;
    gap: 1rem;
    align-items: center;
    text-align: center;
    background: rgba(255, 255, 255, 0.26);
    border: 7px solid var(--orangeCard);
    box-shadow: var(--boxShadow);
    border-radius: 20px;
    padding: 0px 26px 2rem 26px;
}
.card>img{
    transform: scale(0.6);
    margin-bottom: -2rem;
}
.card>span:nth-of-type(2){
    font-size: 16px;
    color: var(--gray);
}
.button-c{
    background: #ffffffff;
    box-shadow: 0px 19px 60px rgba(0, 0, 0, 0.08);
    border: none;
    border-radius: 7px;
    padding: 10px;
    font-size: 16px;
    color: #5290fd;
}
.button-c:hover{
    cursor: pointer;
    background: #5290fd;;
    color: white;
}
@media screen and (max-width:480px) {
   .card{
    width: auto;
   }
    
}