.experience{
    height: 30vh;
    display: flex;
    gap: 5rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 8rem;
}
.achievement{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.circle{
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    background-color: white;
}
.circle::before{
    content: '';
    background-image: linear-gradient(to bottom , #87e6fb 0%, #ffc05c 100%);
    position: absolute;
    border-radius: 100%;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    z-index: -1;
    box-shadow: var(--boxShadow);
}
.achievement>span:nth-of-type(2){
    color: var(--orange);
}
@media screen and (max-width:480px) {
   .experience{
    display: flex;
    gap: 2.5rem;
   }
   .circle{
    width: 3rem;
    height: 3rem;
    font-size: 1.2rem;
    font-weight: bold;
   }
   
   .achievement>span:nth-of-type(1){
    font-size: 0.9rem;
}
   .achievement>span:nth-of-type(2){
    font-size: 0.9rem;
}
 }